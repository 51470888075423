td {
    border: 1px solid #5bc9b1;
    width: 25px;
    height: 25px;
}
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
table{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}
.node_start{
    background: url("../assets/space-ship.svg");
    background-size: cover;
}
.node_end{
    background: url("../assets/planet.svg");
    background-size: cover;
}
.node_wall{
  /* background: url("../assets/asteroid.svg"); */
    animation: pulse 0.5s 1;
    background-color: #202220;
    border: 1px solid #202220;
}

@keyframes pulse{
    0%{
      background-color: grey;

    }
    100%{
      background-color: #202220;
    }
}
.node_visited{
  /* background-color: lightseagreen; */
  background-color: rgba(4, 0, 255, 0.5);
  animation-name: visited;
  animation-duration: 1.2s;
  animation-iteration-count: 1;
  animation-direction: alternate;
}
@keyframes visited{
  0% {
    transform: scale(0.3);
    background-color: rgba(255, 0, 191, 0.5);
    border-radius: 100%;
  }

  50% {
    background-color:  rgba(255, 0, 191, 0.5);
  }

  75% {
    transform: scale(1.2);
    background-color: rgba(4, 0, 255, 0.5);
  }

  100% {
    transform: scale(1);
    background-color:rgba(4, 0, 255, 0.5);
  }
}
.node_path{
  background-color: greenyellow;
  animation-name: path_anime;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  /* animation-iteration-count: infinite; */
  animation-fill-mode: none;
  animation-play-state: running;
}

@keyframes path_anime{
  0% {
    transform: scale(0.3);
    background-color: red;
    border-radius: 100%;
}
50% {
    background-color: orange;
}
100% {
    transform: scale(1);
    background-color: yellow;
}
}
.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chat-btn {
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 2rem;
  color: #fff;
  background-color: #343A40;
  border: 1px solid #343A40;
  border-radius: 25px 25px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  
  display: none;
}
.chat-btn:focus{
  animation: shadow-pulse 1s infinite;
  outline:none;
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0 #343A40;
  }
  100% {
    box-shadow: 0 0 0 20px rgba(130, 90, 164, 0);
  }
}
.chat-body {
  height: auto;
  width: auto;
  min-width: 10vw;
  /* min-height: 300px;
  min-width: 300px; */
  background:#343A40;
  position: absolute;
  bottom: 60px;
  right: 60px;
  box-shadow: 4px 4px 4px gray;
  border-radius: 8px;
  color: black;
  padding: 8px;
  margin-left: 10px;
}
.chat-body .chat-head {
  background: #343A40;
  padding: 10px;
  height: 20px;
  font-family: verdana;
}
.progress2 {
  border-radius: 30px;
  background-color: gray;
}

.progress-bar2 {
  height: 5px;
  border-radius: 30px;
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-moved .progress-bar2 {
  background-color: #f3c623;
  animation: progress 5s 1;
}
@keyframes progress {
  0% {
    width: 0%;
    background: #f9bcca;
  }

  60% {
    /* width: 60%; */
    background: #f3c623;
    box-shadow: 0 0 40px #f3c623;
  }
}
.progress-text{
  color: black;
}
.progress-text:hover .comment{
  display: block;
}
.progress-text:hover .span-text{
  display: none;
}
.comment{
  display: none;
}
.info-title{
  font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.235;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}