@import url(https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap);
body {
  margin: 0;
  font-family: Merriweather Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bar{
    padding-top: 7px;
    font-family: sans-serif;
    font-weight: 700;
    display: inline-block;
    color:white;
    width:40px;
    margin-left:6px;
    background-color:#5bc9b1;
}
.bar-sorted{
    background-color:#b979ec;
    
}
.bar-swap{
    background-color:#57a846;
}
.bar-min{
    background-color: red;
}
.bars{
    
    /* display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: horizontal;
    flex-direction: horizontal;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: -1; */
}
@font-face {
    font-family: Anurati;
    src: url(/static/media/Anurati-Regular.ca8632f4.otf);
}
:root{
    --home-color: #2ED327;
}
.algo-name {
    font-size: 18px;
    font-weight: 700;
    color:white;
}
.card{
    background-color: transparent;
}
.card::after{
    content: "";
    bottom: 0;
    top: 0;
  left: 0;
  position: absolute;
  height: 50%;
  width: 5px;
  color: #fff;
}
.algo-image{
    height: 175px;
    width: auto;
    object-fit:cover;
    fill: #2ED327;
    fill: var(--home-color);
    background-color: transparent;
}
.back{
    background-color: #0E0E0E;
    min-height: 100vh;
}
#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: url("");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    z-index: 0;
    top:0;
    left:0;
  }

.logo{
    /* left: 50%;                         
    transform: translate( -50%,0); */
    position: relative;
    z-index: 100;
    float:top;
}
.links li{
    cursor: pointer;
    margin: 10px;
    fill: #2ED327!important;
    fill: var(--home-color)!important;
    margin-top: 25px;
    /* display: inline; */
}
.links li svg{
    height: 20px;
    width: auto;
}
.links{
    position: -webkit-sticky;
    position: sticky;
    z-index: 100;
    display: flex;
    list-style: none;
    vertical-align: top;
    float: left;
    cursor: pointer;
    /* position: absolute; */
    /* display: flex;
   flex-direction: row; */
   /* Below sets up your display method: flex-start|flex-end|space-between|space-around */
   justify-content: flex-start; 
   /* Below removes bullets and cleans white-space */
   list-style: none;
   padding: 0;
   /* Bonus: forces no word-wrap */
   white-space: nowrap;
}
.main-title{
    font-size: 3rem;
    color: white;
    padding-top: 18vh;
    font-family: Anurati, sans-serif;
    letter-spacing: 5px;
}

.main-title svg{
    fill: #2ED327;
    fill: var(--home-color);
    margin-bottom: 30px;
    height: 80px;
    width: auto;
}
.description-title{
    color: white;
    width: 50%;
    margin: auto;
}
td {
    border: 1px solid #5bc9b1;
    width: 25px;
    height: 25px;
}
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
table{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}
.node_start{
    background: url(/static/media/space-ship.4db25bca.svg);
    background-size: cover;
}
.node_end{
    background: url(/static/media/planet.2f3f3bdb.svg);
    background-size: cover;
}
.node_wall{
  /* background: url("../assets/asteroid.svg"); */
    -webkit-animation: pulse 0.5s 1;
            animation: pulse 0.5s 1;
    background-color: #202220;
    border: 1px solid #202220;
}

@-webkit-keyframes pulse{
    0%{
      background-color: grey;

    }
    100%{
      background-color: #202220;
    }
}

@keyframes pulse{
    0%{
      background-color: grey;

    }
    100%{
      background-color: #202220;
    }
}
.node_visited{
  /* background-color: lightseagreen; */
  background-color: rgba(4, 0, 255, 0.5);
  -webkit-animation-name: visited;
          animation-name: visited;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}
@-webkit-keyframes visited{
  0% {
    transform: scale(0.3);
    background-color: rgba(255, 0, 191, 0.5);
    border-radius: 100%;
  }

  50% {
    background-color:  rgba(255, 0, 191, 0.5);
  }

  75% {
    transform: scale(1.2);
    background-color: rgba(4, 0, 255, 0.5);
  }

  100% {
    transform: scale(1);
    background-color:rgba(4, 0, 255, 0.5);
  }
}
@keyframes visited{
  0% {
    transform: scale(0.3);
    background-color: rgba(255, 0, 191, 0.5);
    border-radius: 100%;
  }

  50% {
    background-color:  rgba(255, 0, 191, 0.5);
  }

  75% {
    transform: scale(1.2);
    background-color: rgba(4, 0, 255, 0.5);
  }

  100% {
    transform: scale(1);
    background-color:rgba(4, 0, 255, 0.5);
  }
}
.node_path{
  background-color: greenyellow;
  -webkit-animation-name: path_anime;
          animation-name: path_anime;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-delay: 0;
          animation-delay: 0;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  /* animation-iteration-count: infinite; */
  -webkit-animation-fill-mode: none;
          animation-fill-mode: none;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

@-webkit-keyframes path_anime{
  0% {
    transform: scale(0.3);
    background-color: red;
    border-radius: 100%;
}
50% {
    background-color: orange;
}
100% {
    transform: scale(1);
    background-color: yellow;
}
}

@keyframes path_anime{
  0% {
    transform: scale(0.3);
    background-color: red;
    border-radius: 100%;
}
50% {
    background-color: orange;
}
100% {
    transform: scale(1);
    background-color: yellow;
}
}
.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chat-btn {
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 2rem;
  color: #fff;
  background-color: #343A40;
  border: 1px solid #343A40;
  border-radius: 25px 25px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  
  display: none;
}
.chat-btn:focus{
  -webkit-animation: shadow-pulse 1s infinite;
          animation: shadow-pulse 1s infinite;
  outline:none;
}
@-webkit-keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0 #343A40;
  }
  100% {
    box-shadow: 0 0 0 20px rgba(130, 90, 164, 0);
  }
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0 #343A40;
  }
  100% {
    box-shadow: 0 0 0 20px rgba(130, 90, 164, 0);
  }
}
.chat-body {
  height: auto;
  width: auto;
  min-width: 10vw;
  /* min-height: 300px;
  min-width: 300px; */
  background:#343A40;
  position: absolute;
  bottom: 60px;
  right: 60px;
  box-shadow: 4px 4px 4px gray;
  border-radius: 8px;
  color: black;
  padding: 8px;
  margin-left: 10px;
}
.chat-body .chat-head {
  background: #343A40;
  padding: 10px;
  height: 20px;
  font-family: verdana;
}
.progress2 {
  border-radius: 30px;
  background-color: gray;
}

.progress-bar2 {
  height: 5px;
  border-radius: 30px;
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-moved .progress-bar2 {
  background-color: #f3c623;
  -webkit-animation: progress 5s 1;
          animation: progress 5s 1;
}
@-webkit-keyframes progress {
  0% {
    width: 0%;
    background: #f9bcca;
  }

  60% {
    /* width: 60%; */
    background: #f3c623;
    box-shadow: 0 0 40px #f3c623;
  }
}
@keyframes progress {
  0% {
    width: 0%;
    background: #f9bcca;
  }

  60% {
    /* width: 60%; */
    background: #f3c623;
    box-shadow: 0 0 40px #f3c623;
  }
}
.progress-text{
  color: black;
}
.progress-text:hover .comment{
  display: block;
}
.progress-text:hover .span-text{
  display: none;
}
.comment{
  display: none;
}
.info-title{
  font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.235;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    border-radius: 5px;
  }
  
  .modal-main {
    position:fixed;
    padding: 10px;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 1000;
    border-radius: 5px;
    font-size: 20px;
  }
  
  .display-block {
      display: block;
  }
  
  .display-none {
    display: none;
  }
.modalImage{
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
}

/* @media only screen and (max-width: 800px) {
    .canvas {
        background-color: #ffffff;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23343a40' fill-opacity='0.21'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        border: 1px solid black;
        margin: 5px;
        margin-top: 50px;
        width: 90%;
        height: 80vh;
    }
  }
  @media only screen and (min-width:800px){
    
  } */
  .canvas{
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23343a40' fill-opacity='0.21'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    border: 1px solid black;
    margin: 5px;
    margin-top: 50px;
    border-radius: 10px;
    /* width: 90vw;
    height: 80vh; */
}
