.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    border-radius: 5px;
  }
  
  .modal-main {
    position:fixed;
    padding: 10px;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 1000;
    border-radius: 5px;
    font-size: 20px;
  }
  
  .display-block {
      display: block;
  }
  
  .display-none {
    display: none;
  }
.modalImage{
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
}